<template>
	<div v-if="store.sessionOk">
		<BlanketOverlayWithPopup v-if="showDemoEditor" title="Demographic Editor"
			width="1100" :hastabs="false" v-on:close="finishCancelDemo" :nobuttons="true"
		>
			<template v-slot:main>
				<DemoEdit :survob="activeSurvOb" v-on:closesave="finishSaveDemoNew($event)"
					:editdemoid="editingDemoId" :demographics="demoListRaw"
				></DemoEdit>
			</template>
		</BlanketOverlayWithPopup>

		<BlanketOverlayWithPopup v-if="showSurveySelector" title="Select Survey"
			width="800" :hastabs="false" v-on:close="showSurveySelector = false"
		>
			<template v-slot:main>
				<div>
					<select class="wl-input-select wl-bg-white" v-model="chosenSurveyId">
						<option v-for="us in userSurveyList" :key="us.id" :value="us.id">{{us.name}}</option>
					</select>
				</div>
			</template>
		</BlanketOverlayWithPopup>

		<BlanketOverlayWithPopup v-if="showLayoutPopup" title="Table Layout Editor"
			width="800" :hastabs="false" v-on:close="showLayoutPopup = false"
		>
			<template v-slot:main>
				<div class="halves-holder">
					<div class="half">
						<p class="bold" style="margin-bottom: 10px;">Columns</p>
						<div v-for="(c, colind) in colItems" :key="colind" class="half-row">
							<div class="namecol">
								{{printNameCol(c)}}
							</div>
							<div>
								<i class="mdi mdi-arrow-up" @click="colItemUp(colind)"></i>
								<i class="mdi mdi-arrow-down" @click="colItemDown(colind)"></i>
								<i class="mdi mdi-close" @click="removeColItem(colind)"></i>
							</div>
						</div>
					</div>
					<div class="half">
						<p class="bold" style="margin-bottom: 10px;">Rows</p>
						<div v-for="(r, rowind) in rowItems" :key="rowind" class="half-row">
							<div class="namecol">
								{{printNameRow(r)}}: {{r.opttext}}
							</div>
							<div>
								<i class="mdi mdi-arrow-up" @click="rowItemUp(rowind)"></i>
								<i class="mdi mdi-arrow-down" @click="rowItemDown(rowind)"></i>
								<i class="mdi mdi-close" @click="removeRowItem(rowind)"></i>
							</div>
						</div>
					</div>
				</div>
			</template>
		</BlanketOverlayWithPopup>

		<BlanketOverlayWithPopup v-if="showJobSelector" title="Select Job"
			width="800" :hastabs="false" v-on:close="showJobSelector = false"
		>
			<template v-slot:main>
				<div>
					<select class="wl-input-select wl-bg-white" v-model="popupChosenJobId">
						<option v-for="us in jobsList" :key="us.id" :value="us.id">{{us.name}}</option>
					</select>
					<button class="option-button colbtn" @click="loadJob">Load Job</button>
				</div>
			</template>
		</BlanketOverlayWithPopup>

		<div id="container" :class="{pageLeftMenuTransposed : store.leftmenuhidden}">
			<LeftMenuWL></LeftMenuWL>
			<div id="bg">
				<TopMenuBar>
					<TopMenuGroup v-if="store.user.perms.panelUser !== 1" label="Options" :hasdropdown="true">
						<TopMenuItem label="Switch Survey" @click="showSurveySelector = true"></TopMenuItem>
						<TopMenuItem label="Create Demo" @click="demoCreate"></TopMenuItem>
						<TopMenuItem label="Save Job" @click="saveAsJob"></TopMenuItem>
						<TopMenuItem label="Save Job Copy" @click="saveAsJob(true)"></TopMenuItem>
						<TopMenuItem label="Load Job" @click="getJobsList"></TopMenuItem>
<!--						<TopMenuItem v-if="hiderawvals === false" label="Hide Raw Values" @click="hiderawvals = true"></TopMenuItem>-->
<!--						<TopMenuItem v-if="hiderawvals === true" label="Show Raw Values" @click="hiderawvals = false"></TopMenuItem>-->
					</TopMenuGroup>
				</TopMenuBar>

				<div class="interrogate-page">
					<div class="question-list-section">
						<div class="question-search-holder">
							<input type="text" class="wl-input-text" v-model="filterTextQuestions" placeholder="Search Questions">
						</div>
						<div class="question-list">
							<div v-for="q in filteredQuestions" :key="q.id" class="question-list-item">
								<div class="question-list-item-inner">
									<div class="question-list-item-title">
										{{getNiceTitle(q)}}
										<span @click="addAllToRowItems(q)" title="Add all options as Rows">
											<i class="mdi mdi-table-row"></i>
										</span>
										<span @click="addAllToQoptsColumnItems(q)" title="Add all options as Columns">
											<i class="mdi mdi-table-column"></i>
										</span>
									</div>
									<div class="question-options-list">
										<div>
											<div v-for="opt in q.options" :key="opt.optid" class="question-option">
												<span>{{opt.optid}}: {{opt.opttext}}
													<span @click="addToRowItems(q, opt, true)" title="Add as Row"><i class="mdi mdi-table-row"></i></span>
													<span @click="addQoptToColItems(q, opt, true)" title="Add as Column"><i class="mdi mdi-table-column"></i></span>
												</span>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="demo-list-section">
						<div class="question-search-holder">
							<input type="text" class="wl-input-text" v-model="filterTextDemos" placeholder="Search Crosstabs/Demographics">
						</div>
						<div class="demo-list">
							<div v-for="d in filteredDemographics" :key="d.id" class="demo-list-item">
								<div class="question-list-item-inner">
									{{d.name}}
									<span @click="addDemoToRowItems(d)" title="Add as Row"><i class="mdi mdi-table-row"></i></span>
									<span @click="addToColItems(d)" title="Add as Column"><i class="mdi mdi-table-column"></i></span>
									<span @click="setAsBaseDemo(d)" title="Set as Base Demo"><i class="mdi mdi-database-arrow-right-outline"></i></span>
									<span v-if="demoCanBeEdited(d)" @click="editDemo(d)" style="margin-left: 5px;" title="Edit demographic"><i class="mdi mdi-pencil"></i></span>
								</div>
							</div>
						</div>
					</div>
					<div class="results-section">
						<div class="" style="display: flex; align-items: center;">
							<button @click="fetchData" class="option-button colbtn">Fetch Data</button>
							<div>
								<select v-model="chosenMetric" class="wl-input-select">
									<option value="pctAllDemo">% of All Demo</option>
									<option value="pctTotalSurvey">% of Total Survey</option>
									<option value="pctAnswered">% Answered</option>
									<option value="count">Count</option>
									<option value="sumWeights">Sum Weights</option>
									<option value="qSampleSize">Q Sample Size</option>
								</select>
							</div>
							<div class="checkholders">
								<label>
									<input type="checkbox" v-model="showHighlightCells" id="showhighlights" />
									<span class="spanner">Highlights</span>
								</label>
								<label>
									<input type="checkbox" v-model="useShortNames">
									Short Names
								</label>
							</div>
							<div class="checkholders">
								<label v-if="trendSurveys.length > 0">
									<input type="checkbox" v-model="displayTrendCols">
									Trends
								</label>
							</div>
							<div class="functions-holder">
								<span @click="exportToExcel" title="Export to Excel" v-if="rowItems.length > 0 || colItems.length > 0">
									<i class="mdi mdi-file-excel-outline"></i>
								</span>
								<span @click="copyTableToClipboard" title="Copy to clipboard" v-if="rowItems.length > 0 || colItems.length > 0">
									<i class="mdi mdi-content-copy"></i>
								</span>
								<span @click="saveAsJob" title="Save Job / View" v-if="rowItems.length > 0 || colItems.length > 0">
									<i class="mdi mdi-content-save-outline"></i>
								</span>
								<span @click="saveAsJob(true)" title="Save As (new Job / View)" v-if="rowItems.length > 0 || colItems.length > 0">
									<i class="mdi mdi-content-save-move-outline"></i>
								</span>
							</div>
						</div>
						<div style="margin: 5px 0 10px;">
							<span class="text-link" @click="showLayoutPopup = true">Edit Table Layout</span>
							<span style="margin-left: 10px;" class="text-link" @click="clearTable">Clear Table</span>
							<span style="margin-left: 10px;" class="text-link" @click="clearRows">Clear Rows</span>
							<span style="margin-left: 10px;" class="text-link" @click="clearCols">Clear Columns</span>
							<span style="margin-left: 10px;" class="text-link" @click="revertToSnapshot">Undo</span>
						</div>
						<div id="excel-basic-wrapper">
							<div style="margin: 10px 0;">
								<table>
									<tr v-if="activeJobId > 0">
										<td class="bold">Job: {{activeJobName}}</td>
									</tr>
									<tr>
										<td class="bold">
											Base Demo: {{getDemoName(baseDemoId)}}
											<i v-if="baseDemoId !== 0" class="mdi mdi-close" @click="baseDemoId = 0"></i>
										</td>
									</tr>
								</table>
							</div>

							<table v-if="rowItems.length > 0 || colItems.length > 0" class="interrogate-table">
								<thead>
									<tr>
										<td></td>
										<td v-for="(cval, colind) in dataHeaderRow" :key="colind" class="result-td">
											{{cval}}
											<i v-if="displayTrendCols === false" class="mdi mdi-close" @click="removeColItem(colind)"></i>
										</td>
									</tr>
								</thead>
								<tbody>
								<tr v-for="(r, rowind) in dataResultRows" :key="rowind">
									<td v-for="(cval, colind) in r" :key="colind" class="result-td"
										:class="getCellHighlightClass(colind, cval)" :data-t="getExcelCellDataType(colind)"
									>
										{{cval}}
										<i v-if="colind === 0" class="mdi mdi-close" @click="removeRowItem(rowind)"></i>
									</td>
								</tr>
								</tbody>
							</table>

						</div>

					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import {store} from '@/store.js'
import LeftMenuWL from "@/components/LeftMenuWL";
import {globalMixin} from "@/mixins";
import TopMenuBar from "@/components/TopMenuBar";
import TopMenuGroup from "@/components/TopMenuGroup.vue";
import TopMenuItem from "@/components/TopMenuItem.vue";
import BlanketOverlayWithPopup from "@/components/BlanketOverlayWithPopup.vue";
import TableToExcel from "@linways/table-to-excel";
import DemoEdit from "@/components/dashboard/DemoEdit.vue";

export default {
	name: "InterrogatePage",
	mixins: [globalMixin],
	components: {
		DemoEdit,
		BlanketOverlayWithPopup,
		TopMenuItem,
		TopMenuGroup,
		TopMenuBar,
		LeftMenuWL
	},
	props: {},
	data: function () {
		return {
			store: store,

			chosenSurveyId: null,
			chosenCompletesChoice: 'only',
			chosenWeightingChoice: true,
			//percentSampleBase: 'answered', //answered, alldemo, totalsurvey
			hiderawvals: false,

			weightingIsAvailableForSurvey: true,

			filterTextQuestions: '',
			filterTextDemos: '',

			userSurveyList: [],
			demoListRaw: [],
			jobsList: [],

			activeSurvOb: null,
			results: [],

			showDemoEditor: false,
			isExportingToExcel: false,
			editingDemoId: 0,

			showSurveySelector: false,

			trendSurveys: [],
			trendDataForQ: {},
			displayTrendCols: false,

			filterInfDialRadioListenersOnly: false,

			activeJobId: 0,
			activeJobName: '', //for display only
			rowItems: [],
			colItems: [],
			baseDemoId: 0,
			useShortNames: true,
			chosenMetric: 'pctAllDemo', //pctAllDemo, pctTotalSurvey, pctAnswered, count, qSampleSize, sampleSizeAllDemo, sumWeights

			showJobSelector: false,
			popupChosenJobId: null,

			showHighlightCells: false,

			showLayoutPopup: false,

			undoSnapshot: {
				rowItems: [],
				colItems: [],
			},

		}
	},
	computed: {
		lookupSurveyIds() {
			if(this.displayTrendCols === true && this.trendSurveys.length > 0) {
				return this.trendSurveys.map(item => item.survid)
			}
			return [this.chosenSurveyId]
		},
		questions() {
			if(this.activeSurvOb && this.activeSurvOb.questions) return this.activeSurvOb.questions
			return []
		},
		demoList() {
			let dems = [
				{id: 0, name: 'Total'}
			]
			return dems.concat(this.demoListRaw)
		},
		uAuthStr() {
			return 'wlsesstoken='+this.store.wlsesstoken+'&uid='+this.store.user.id;
		},
		filteredQuestions() {
			if(this.filterTextQuestions === '') return this.questions
			let ftext = this.filterTextQuestions.toLowerCase()
			let ftextarr = ftext.split(' ')
			return this.questions.filter(function(q) {
				let qtext = q.qtext_stripped.toLowerCase()
				for(let ft of ftextarr) {
					if(qtext.indexOf(ft) === -1) return false
				}
				return true
			})
		},
		filteredDemographics() {
			if(this.filterTextDemos === '') return this.demoList
			let ftext = this.filterTextDemos.toLowerCase()
			let ftextarr = ftext.split(' ')
			return this.demoList.filter(function(d) {
				let dname = d.name.toLowerCase()
				for(let ft of ftextarr) {
					if(dname.indexOf(ft) === -1) return false
				}
				return true
			})
		},
		dataHeaderRow() {
			let row = []
			for(let c of this.colItems) {
				for(let survid of this.lookupSurveyIds) {
					let nm = this.printNameCol(c)
					if(this.lookupSurveyIds.length > 1) {
						let sob = this.trendSurveys.find(item => item.survid === survid)
						if(sob) nm = sob.survname_internal + ': ' + nm
					}
					row.push(nm)
				}
			}
			return row
		},
		dataResultRows() {
			let rows = []
			for(let r of this.rowItems) {
				let myrow = []
				myrow.push(this.printNameRow(r)+': '+r.opttext)
				for(let c of this.colItems) {
					for(let survid of this.lookupSurveyIds) {
						let cellVal = this.getDatapoint(survid, c.id, r.qid, r.optid, this.chosenMetric)
						myrow.push(cellVal)
					}
				}
				rows.push(myrow)
			}
			return rows
		},
	},
	methods: {
		demoCanBeEdited(demoOb) {
			if(!demoOb.settings) return false
			if(demoOb.settings.length === 0) return false
			if(demoOb.userid !== this.store.user.id && this.store.user.superUser === 0) return false
			return true
		},
		editDemo(demoOb) {
			this.editingDemoId = demoOb.id
			this.showDemoEditor = true
		},
		getCellHighlightClass(colind, cellVal) {
			let classStr = 'ctr '
			if(colind === 0) classStr = ''
			if(this.showHighlightCells === true) {
				let valarr = []
				for(let i=0; i<this.dataResultRows.length; i++) {
					valarr.push(parseFloat(this.dataResultRows[i][colind]))
				}
				valarr.sort((a, b) => b - a)
				let labels = ['high', 'high2', 'high3', 'high4']
				for(let i=0; i<4; i++) {
					if(cellVal >= valarr[i]) return classStr + ' ' + labels[i]
				}
			}
			return classStr
		},
		getDemoName(demoId) {
			let ob = this.demoListRaw.find(item => item.id === demoId)
			if(ob) return ob.name
			return 'Total'
		},
		getJobName(jobid) {
			let ob = this.jobsList.find(item => item.id === jobid)
			if(ob) return ob.name
			return ''
		},
		copyTableToClipboard() {
			let out = ''
			out += 'Base Demo: '+this.getDemoName(this.baseDemoId)+'\n'
			out += 'Metric: '+this.chosenMetric+'\n\n'
			out += '\t'
			for(let c of this.dataHeaderRow) {
				out += c + '\t'
			}
			out = out.slice(0, -1)
			out += '\n'
			for(let r of this.dataResultRows) {
				for(let c of r) {
					out += c + '\t'
				}
				out = out.slice(0, -1)
				out += '\n'
			}
			out = out.slice(0, -1)
			navigator.clipboard.writeText(out)
		},
		clearTable() {
			this.clearCols()
			this.clearRows()
		},
		clearRows() {
			this.rowItems = []
		},
		clearCols() {
			this.colItems = []
		},
		saveAsJob(newcopy = false) {
			let jobid = this.activeJobId
			if(newcopy === true) jobid = 0
			let jobname = '' //only needed for new/copied jobs
			if(jobid === 0) {
				jobname = prompt('Enter a name for this job')
				if(jobname === null || jobname === '') return
			}
			let targ = this.store.urlroot + "central/post-vue/interrogate/save-interrogate-job"
			let ob = {
				columns: this.colItems,
				rows: this.rowItems,
				metric: this.chosenMetric,
				useShortNames: this.useShortNames,
			}
			let sendob = {
				survid: this.chosenSurveyId,
				jobId: jobid,
				jobname: jobname,
				jsob: ob,
			}
			this.simplePost(targ, sendob).then(function (ret) {
				if(ret.jobId) {
					this.activeJobId = ret.jobId
					if(jobname !== '') {
						document.title = jobname
						this.activeJobName = jobname
					}
				}
			}.bind(this))
		},
		getJobsList() {
			let targ = this.store.urlroot + "central/post-vue/interrogate/get-user-jobs"
			let sendob = {
				survid: this.chosenSurveyId,
			}
			this.simplePost(targ, sendob).then(function (ret) {
				if(ret.jobs) {
					this.jobsList = ret.jobs
					this.showJobSelector = true
				}
			}.bind(this))
		},
		loadJob() {
			let ob = this.jobsList.find(item => item.id === this.popupChosenJobId)
			if(ob) {
				this.activeJobId = ob.id
				this.showJobSelector = false
				this.rowItems = ob.jsob.rows
				this.colItems = ob.jsob.columns
				this.chosenMetric = ob.jsob.metric
				this.useShortNames = ob.jsob.useShortNames
				document.title = this.getJobName(ob.id)
				this.activeJobName = this.getJobName(ob.id)
			}
			else {
				alert('Job not found')
			}
		},
		printNameCol(c) {
			if(this.useShortNames === true && c.shortname) return c.shortname
			return c.name
		},
		printNameRow(r) {
			if(this.useShortNames === true && r.qtext_short) return r.qtext_short
			return r.qtext
		},
		getNiceTitle(q, short = false) {
			let title = ''
			if(q.trendkey && q.trendkey.length > 0) {
				title += q.trendkey.toUpperCase() + ': '
				if(short) return q.trendkey.toUpperCase()
			}
			if(['MatrixHeadRadio','MatrixHeadCheck'].includes(q.type)) {
				let ar = q.qtext_stripped.split("|")
				title += ar[0]
			}
			else {
				title += q.qtext_stripped
			}
			if(q.qgroupkey != '') {
				title += ' [' + q.qgroupkey + ']'
			}
			return title
		},
		finishSaveDemoNew(newDemoId) {
			console.log('New Demo ID is: '+newDemoId)
			this.showDemoEditor = false
			this.editingDemoId = 0
			this.getDemosForSurvey()
		},
		finishCancelDemo() {
			this.showDemoEditor = false
			this.editingDemoId = 0
		},
		getPrepData() {
			let targ = this.store.urlroot + "central/post-vue/interrogate/get-initial-data"
			let sendob = {
				survid: this.chosenSurveyId,
			}
			this.simplePost(targ, sendob).then(function (ret) { //use ret in function as returned data
				this.isFetching = false
				if(ret.surveys) this.userSurveyList = ret.surveys
				if(ret.survey) {
					this.activeSurvOb = ret.survey
					this.getTrendSurveys()
				}
			}.bind(this))
		},
		getDemosForSurvey() {
			let targ = this.store.urlroot + "central/post-vue/dashboard/get-demos-for-survey"
			let sendob = {
				survid: this.chosenSurveyId,
			}
			this.simplePost(targ, sendob).then(function (ret) { //use ret in function as returned data
				this.isFetching = false
				if(ret.demoListForSurvey) this.demoListRaw = ret.demoListForSurvey
			}.bind(this))
		},
		getTrendSurveys() { //get the surveys that we need to look at for trend data
			if(this.activeSurvOb.surveyGroups && this.activeSurvOb.surveyGroups.length > 0) {
				let maxTrendAllowed = 10
				let mySurveyGroup = this.activeSurvOb.surveyGroups[0]
				let targ = this.store.urlroot + "central/post-vue/dashboard/get-trend-surveygroup"
				let sendob = {
					survid: this.chosenSurveyId,
					surveyGroup: mySurveyGroup,
					maxItems: maxTrendAllowed,
				}
				this.simplePost(targ, sendob).then(function (ret) {
					this.trendSurveys = ret['trendsurvs'];
				}.bind(this))
			}
		},
		demoCreate() {
			this.editingDemoId = 0
			this.showDemoEditor = true
		},
		exportToExcel() {
			this.isExportingToExcel = true
			let oldviewtype = this.pageViewType
			this.pageViewType = 'standard'
			setTimeout(function() {
				let content = document.querySelector("#excel-basic-wrapper")
				let contentT = content.cloneNode(true)
				contentT.innerHTML = contentT.innerHTML.replace(/,/g,''); //replaces , globally
				TableToExcel.convert(contentT, {
					name: "Table.xlsx",
					sheet: {
						name: 'Results'
					}
				});
				this.isExportingToExcel = false
				this.pageViewType = oldviewtype
			}.bind(this), 100)
		},
		takeUndoSnapshot() {
			this.undoSnapshot = {
				rowItems: JSON.parse(JSON.stringify(this.rowItems.slice())),
				colItems: JSON.parse(JSON.stringify(this.colItems.slice())),
			}
		},
		addAllToRowItems(q) {
			this.takeUndoSnapshot()
			for(let opt of q.options) {
				this.addToRowItems(q, opt)
			}
		},
		addToRowItems(q, opt, takeSnapshot = false) {
			if(takeSnapshot === true) this.takeUndoSnapshot()
			let myitem = {
				qid: q.qid,
				qtext: this.getNiceTitle(q),
				qtext_short: this.getNiceTitle(q, true),
				optid: opt.optid,
				opttext: opt.opttext,
				demoId: null,
				isDemo: false,
			}
			this.rowItems.push(myitem)
		},
		addDemoToRowItems(d) {
			this.takeUndoSnapshot()
			let myitem = {
				qid: 'dem*_'+d.id,
				qtext: d.name,
				optid: 1,
				opttext: '',
				demoId: d.id,
				isDemo: true,
			}
			this.rowItems.push(myitem)
		},
		removeRowItem(rowind) {
			this.takeUndoSnapshot()
			this.rowItems.splice(rowind, 1)
		},
		addToColItems(d) {
			this.takeUndoSnapshot()
			this.colItems.push(d)
		},
		addQoptToColItems(q, opt, takeSnapshot = false) {
			if(takeSnapshot === true) this.takeUndoSnapshot()
			let myid = q.qid + '_' + opt.optid
			this.colItems.push({
				id: myid,
				name: this.getNiceTitle(q) + ': ' + opt.opttext,
				shortname: this.getNiceTitle(q, true) + ': ' + opt.opttext,
			})
		},
		addAllToQoptsColumnItems(q) {
			this.takeUndoSnapshot()
			for(let opt of q.options) {
				this.addQoptToColItems(q, opt)
			}
		},
		removeColItem(colind) {
			this.takeUndoSnapshot()
			this.colItems.splice(colind, 1)
		},
		colItemUp(colind) {
			if(colind > 0) {
				this.takeUndoSnapshot()
				let item = this.colItems[colind]
				this.colItems.splice(colind, 1)
				this.colItems.splice(colind-1, 0, item)
			}
		},
		colItemDown(colind) {
			if(colind < this.colItems.length-1) {
				this.takeUndoSnapshot()
				let item = this.colItems[colind]
				this.colItems.splice(colind, 1)
				this.colItems.splice(colind+1, 0, item)
			}
		},
		rowItemUp(rowind) {
			if(rowind > 0) {
				this.takeUndoSnapshot()
				let item = this.rowItems[rowind]
				this.rowItems.splice(rowind, 1)
				this.rowItems.splice(rowind-1, 0, item)
			}
		},
		rowItemDown(rowind) {
			this.takeUndoSnapshot()
			if(rowind < this.rowItems.length-1) {
				let item = this.rowItems[rowind]
				this.rowItems.splice(rowind, 1)
				this.rowItems.splice(rowind+1, 0, item)
			}
		},
		setAsBaseDemo(d) {
			this.baseDemoId = d.id
		},
		fetchData() {
			let targ = this.store.urlroot + "central/post-vue/interrogate/get-interrogate-data"
			let demoIds = []
			for(let c of this.colItems) {
				if(!demoIds.includes(c.id)) demoIds.push(c.id)
			}
			let qids = []
			for(let r of this.rowItems) {
				if(!qids.includes(r.qid)) qids.push(r.qid) //standard option row
			}
			let sendob = {
				survids: this.lookupSurveyIds,
				demoids: demoIds,
				qids: qids,
				baseDemoId: this.baseDemoId,
				primarySurveyId: this.chosenSurveyId,
			}
			return this.simplePost(targ, sendob).then(function (ret) { //use ret in function as returned data
				this.isFetching = false
				if(ret.results) this.results = ret.results
				this.store.showLoader = false
			}.bind(this))
		},
		getDatapoint(survid, demoId, qid, optid, valtype = 'pctAllDemo') {
			//valtypes = pctAllDemo, pctTotalSurvey, pctAnswered, count, qSampleSize, sampleSizeAllDemo, sumWeights
			//results[demoId][qid]
			if(this.results[survid] === undefined) return ''
			if(this.results[survid][demoId] === undefined) return ''
			if(this.results[survid][demoId][qid] === undefined) return ''
			let res = this.results[survid][demoId][qid]['results']
			if(res) {
				let optres = res.find(item => item.key === optid)
				if(optres) {
					if(valtype === 'pctAllDemo') return optres.pctSumWeightsOfAllDemo
					if(valtype === 'pctTotalSurvey') return optres.pctSumWeightsOfTotalSample
					if(valtype === 'pctAnswered') return optres.pctSumWeightsOfAnswered
					if(valtype === 'count') return optres.count
					if(valtype === 'sumWeights') return Math.round(optres.sumWeights)
					if(valtype === 'qSampleSize') return this.results[survid][demoId][qid].sampleSize
					//if(valtype === 'sampleSizeAllDemo') return optres.sampleSizeAllDemo
				}
			}
			return ''
		},
		getExcelCellDataType(colind) {
			if(colind > 0) return 'n'
			return 's'
		},
		revertToSnapshot() {
			this.rowItems = JSON.parse(JSON.stringify(this.undoSnapshot.rowItems))
			this.colItems = JSON.parse(JSON.stringify(this.undoSnapshot.colItems))
		}
	},
	watch: {
		chosenSurveyId() {
			if(this.chosenSurveyId > 0) {
				this.$router.push('/interrogate/'+this.chosenSurveyId)
				this.showSurveySelector = false
			}
		},
		weightingIsAvailableForSurvey() {
			if(this.weightingIsAvailableForSurvey === false) this.chosenWeightingChoice = false
		},
	},
	mounted() {

	},
	created() {
		document.title = "Interrogate Survey"
		this.store.activeLmenuLink = 'interrogate'
		this.store.leftmenuhidden = true
		let urlparams = this.$route.params
		if(urlparams.surveyid !== undefined) {
			this.chosenSurveyId = parseInt(urlparams.surveyid)
			this.getDemosForSurvey() //get the specific demos available for this survey
		}
		else {
			this.showSurveySelector = true
		}
		this.getPrepData()
	}
}
</script>

<style scoped>
.interrogate-page {
	display: flex;
	height: calc(100vh - 70px);
	width: 100%;
	position: fixed;
	margin-top: 70px;
}
.question-list-section {
	width: 320px;
	height: calc(100vh - 70px);
	font-size: 12px;
	flex-shrink: 0;
}
.question-search-holder {
	height: 30px;
}
.question-search-holder input {
	width: 100%;
	height: 30px;
	box-sizing: border-box;
	padding: 0 10px;
}
.question-list {
	overflow-y: auto;
	height: calc(100% - 50px);
	top: 50px;
}
.question-list-item-inner {
	border-bottom: 1px solid #DDD;
	padding: 8px 10px;
}
.question-list-item-title {
	font-weight: bold;
	margin-bottom: 5px;
}
.question-options-list {

}
.question-option {
	margin: 3px 0 3px 20px;
}
.demo-list-section {
	width: 300px;
	height: calc(100vh - 70px);
	border-left: 1px solid #DDD;
	font-size: 12px;
	flex-shrink: 0;
}
.demo-list {
	overflow-y: auto;
	height: calc(100% - 50px);
	top: 50px;
}
.results-section {
	padding: 20px 30px 20px 30px;
	border-left: 1px solid #DDD;
	width: calc(100% - 842px);
	overflow-x: auto;
	overflow-y: auto;
	position: relative;
}
#excel-basic-wrapper {
	overflow-x: auto;
}
.interrogate-table {
	border-collapse: collapse;
	border: 1px solid #CCC;
}
.interrogate-table tr:nth-of-type(odd) {
	background-color: #F9F9F9;
}
.interrogate-table tr td:first-of-type {
	border-left: 1px solid #CCC;
	min-width: 250px;
	word-wrap: break-word;
}
.interrogate-table td {
	padding: 8px 5px 6px;
	cursor: default;
	border-left: 1px solid #EFEFEF;
	border-bottom: 1px solid #EFEFEF;
}
.interrogate-table tbody tr:last-of-type td {
	border-bottom: 1px solid #CCC;;
}
.interrogate-table td.result-td {
	max-width: 100px;
}
.interrogate-table .ctr {
	text-align: center;
}
.interrogate-table td .mdi {
	cursor: pointer;
}
.mdi-table-row {
	font-size: 16px;
	margin-left: 5px;
	cursor: pointer;
}
.mdi-table-column {
	font-size: 14px;
	margin-left: 5px;
	cursor: pointer;
}
.functions-holder .mdi {
	font-size: 22px;
	cursor: pointer;
	margin-left: 5px;
}
.checkholders {
	margin-right: 10px;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	gap: 3px;
}
.high {
	background-color: #23B723;
}
.high2 {
	background-color: #48DC48;
}
.high3 {
	background-color: #7FE881;
}
.high4 {
	background-color: #C0F5C1;
}
.halves-holder {
	display: flex;
	gap: 30px;
}
.half {
	width: 50%;
}
.half-row {
	border: 1px solid #DDD;
	padding: 7px 10px;
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin: 5px 0;
}
.half-row .namecol {
	max-width: 300px;
	overflow: hidden;
}
.half-row .mdi {
	cursor: pointer;
	margin-left: 3px;
}
</style>