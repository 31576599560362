<template>
	<div class="holder">
		<div class="boxer">
			<i v-if="is_selected" class="mdi mdi-check"></i>
		</div>
		<div class="textholder" :class="{boldme : is_selected}">
			<span style="padding-top: 2px;">{{label}}</span>
		</div>
	</div>
</template>

<script>
import {store} from '@/store.js'
import {globalMixin} from "@/mixins";

export default {
	name: "SubTreeItem",
	components: {},
	props: {
		label: String,
		is_selected: Boolean
	},
	mixins: [globalMixin],
	data: function () {
		return {
			store: store,

		}
	},
	computed: {},
	methods: {},
	watch: {},
	mounted() {

	}
}
</script>

<style scoped>
.holder {
	padding: 2px 5px;
	margin: 2px 0;
	display: flex;
	align-items: center;
	cursor: default;
	user-select: none;
}
.boxer {
	margin-right: 7px;
	height: 15px;
	width: 15px;
	border: 1px solid #DDD;
	user-select: none;
}
.textholder {
	display: flex;
	align-items: center;
	user-select: none;
}
</style>