<template>
	<div class="demo-creator contained-popup-content">
		<div>
			<div class="demos-halves-flex">
				<div class="demo-left-side">
					<div>
						<p style="font-weight: bold; margin-bottom: 5px;">
							Demo Name
							<span @click="deleteDemo" style="font-size: 10px; margin: 0 10px 0 0; cursor: pointer; color: #EE0000; text-decoration: underline">Delete</span>
						</p>
						<input type="text" v-model="editDemoName" style="width: 300px; padding: 10px;" />
						<span @click="saveDemo" class="option-button colbtn" style="margin: 0 20px 0 10px; display: inline-block;">Save</span>
						<span @click="clearAll" style="font-size: 10px; margin: 0 10px 0 0; cursor: pointer; color: #28A; text-decoration: underline">Reset</span>
					</div>
					<div v-if="store.user.superUser === 1" style="margin-top: 15px; margin-bottom: 25px;">
						<span class='boldme'>Global or User Owned Demo</span><br/><br/>
						<select v-model="saveAsGlobalDemo">
							<option :value="false">User Owner</option>
							<option :value="true">Global Demo</option>
						</select>
					</div>

					<div class="attributes-list">
						<div>
							<div v-for="q in filteredQuestions" :key="q.qid">
								<span class="dhead" style="user-select: none;">{{q.qtext_stripped}}</span><br/>
								<div v-for="(opt, ind) in q.options" :key="ind"
									@mousedown.left="selectionToggleStart(getQDemoKey(q)+'|'+opt.optid)"
									@mouseover="selectionToggleDrag(getQDemoKey(q)+'|'+opt.optid)"
								>
									<SubTreeItem :label="opt.opttext" :is_selected="selections.includes(getQDemoKey(q)+'|'+opt.optid)"></SubTreeItem>
								</div>
							</div>
						</div>
					</div>

				</div>
				<div class="demo-right-side">
					<div class="shown-selections">
						<p style="font-weight: bold; margin-bottom: 5px;">Selections</p>
						<div v-for="(selarr, selindex) in selectionsArray" :key="selindex">
							<div class="arraygroup" :class="{activearray : activeSelectionIndex === selindex}">
								<p @click="activeSelectionIndex = selindex" class="addtogroup">
									<span v-if="selindex !== activeSelectionIndex">Add to this selection group</span>
									<span v-else>Active selection group</span>
								</p>
								<div v-for="sel in selectedAttributesView(selindex)" :key="sel.desc">
									<p class="group-head">{{sel[0].desc}}</p>
									<div v-for="item in sel" :key="item.selkey" style="margin-left: 20px;">
										{{item.item_desc}}
										<span class="mdi mdi-close" @click="selarr.splice(selarr.indexOf(item.selkey), 1)"></span>
									</div>
								</div>
							</div>
							<div v-if="selindex < selectionsArray.length - 1">OR</div>
						</div>

						<div class="addor" @click="addSelectionsGroup">Add an OR selections group</div>
					</div>
				</div>
			</div>

		</div>
	</div>
</template>

<script>
import {globalMixin} from "@/mixins";
import {store} from "@/store";
import SubTreeItem from "@/components/SubTreeItem";

export default {
	name: 'DemoEdit',
	mixins: [globalMixin],
	props: {
		survob: {
			type: Object,
			default: () => {}
		},
		editdemoid: {
			type: Number,
			default: 0
		},
		demographics: {
			type: Array,
			default: () => []
		},
	},
	components: {
		SubTreeItem,
	},
	data() {
		return {
			store: store,

			editDemoName: '',
			selectionsArray: [[]],
			activeSelectionIndex: 0,
			saveAsGlobalDemo: false,

			ageMin: null,
			ageMax: null,

			isSaving: false,

		}
	},
	computed: {
		selections: {
			get() {
				return this.selectionsArray[this.activeSelectionIndex]
			},
			set(value) {
				this.selectionsArray[this.activeSelectionIndex] = value
			}
		},
		sourceDemoOb() {
			if(this.editdemoid === 0) {
				return {
					id: 0,
					survid: this.survob.survid,
					name: '',
					userid: this.store.user.id,
					settings: [],
				}
			}
			return this.demographics.find(item => item.id === this.editdemoid)
		},
		hasActualYearAgeQ() {
			if(this.survob.ageq && this.survob.ageq > 0) {
				let q = this.survob.questions.find(item => item.qid === this.survob.ageq)
				if(q) {
					if(['Text','Number','Select-One'].includes(q.type)) {
						return true
					}
				}
			}
			return false
		},
		filteredQuestions() {
			let qs = this.survob.questions.filter(item => !['HeaderText','Number','Text'].includes(item.type))
			if(this.hasActualYearAgeQ && this.survob.ageq > 0) {
				qs = qs.filter(item => item.qid !== this.survob.ageq)
			}
			return qs
		},
	},
	methods: {
		addSelectionsGroup() {
			this.selectionsArray.push([])
			this.activeSelectionIndex = this.selectionsArray.length - 1
		},
		removeSelectionsGroup(index) {
			if(this.selectionsArray.length > 1) {
				this.selectionsArray.splice(index, 1)
				if(!this.selectionsArray[this.activeSelectionIndex]) this.activeSelectionIndex = 0
			}
		},
		selectedAttributesView(selIndex) {
			let mysels = JSON.parse(JSON.stringify(this.selectionsArray[selIndex]))
			for(let i=0; i<mysels.length; i++) {
				let s = mysels[i]
				let sarr = s.split("|")
				let skey = sarr[0]
				let sval = parseInt(sarr[1])
				if(sval < 10) sval = '00'+sval
				else if(sval < 100) sval = '0'+sval
				mysels[i] = skey+'|'+sval
			}
			mysels.sort()
			let ret = {}
			for(let s of mysels) {
				let sarr = s.split("|")
				let skey = sarr[0]
				let sval = parseInt(sarr[1])
				let att = this.survob.questions.find(item => item.trendkey === skey || item.colcode === skey || item.qid === parseInt(skey))
				if(att) {
					let opt = att.options.find(item => item.optid === sval)
					if(opt) {
						if(!ret[skey]) ret[skey] = []
						ret[skey].push({desc: att.qtext_stripped, item_desc: opt.opttext, selkey: skey+'|'+sval})
					}
				}
			}
			return ret
		},
		selectionToggleDrag(elemId) {
			if(window.mouseIsDown === 1) {
				if(!this.selections.includes(elemId) && this.mouseChangeType === 'add') this.selections.push(elemId)
				else if(this.selections.includes(elemId) && this.mouseChangeType === 'remove') this.selections.splice(this.selections.indexOf(elemId), 1)
			}
		},
		selectionToggleStart(elemId) {
			if(!this.selections.includes(elemId)) this.mouseChangeType = 'add'
			else this.mouseChangeType = 'remove'
			if(!this.selections.includes(elemId) && this.mouseChangeType === 'add') this.selections.push(elemId)
			else if(this.selections.includes(elemId) && this.mouseChangeType === 'remove') this.selections.splice(this.selections.indexOf(elemId), 1)
		},
		clearAll() {
			this.selections = []
		},
		updateSelectionsFromEdit() { //run on mount and after specific survey info is chosen
			let arrindex = 0
			for(let arr of this.sourceDemoOb.settings) {
				if(!this.selectionsArray[arrindex]) this.selectionsArray[arrindex] = []
				for(let item of arr) {
					this.selectionsArray[arrindex].push(item)
				}
				arrindex++
			}
		},
		deleteDemo() {
			let conf = confirm('Are you sure you want to delete this demographic?')
			if(conf) {
				let targ = this.store.urlroot + "central/post-vue/dashboard/delete-demographic"
				let sendob = {
					demoId: this.editdemoid,
				}
				this.simplePost(targ, sendob).then(function (ret) { //use ret in function as returned data
					if(ret.error) this.showKalertError(ret.error)
					if(ret.success) this.$emit('closesave', ret.success.demoId)
				}.bind(this))
			}
		},
		saveDemo() {
			if(this.editDemoName.length === 0) {
				alert('Please enter a name for your demographic');
				return false;
			}
			let empties = []
			let oktosave = false
			let counter = 0
			for(let a of this.selectionsArray) {
				if(a.length === 0) empties.push(counter)
				else oktosave = true
				counter++
			}
			empties.reverse()
			for(let e of empties) {
				this.removeSelectionsGroup(e)
			}
			if(oktosave === false) {
				alert('Please make some selections')
				return false;
			}
			let self = this
			self.store.showLoader = true
			let ownerId = this.store.user.id
			if(this.editdemoid > 0 && this.sourceDemoOb.userid > 0) { //editing existing
				ownerId = this.sourceDemoOb.userid
			}
			else {
				if (this.store.user.id === 1 && this.saveAsGlobalDemo === true) ownerId = 0
			}
			let sendob = {
				survid: this.survob.survid,
				demoName: this.editDemoName,
				selectionsArray: self.selectionsArray,
				ownerId: ownerId,
				demoId: this.editdemoid,
			}
			let targ = this.store.urlroot + "central/post-vue/dashboard/save-demographic-new"
			this.simplePost(targ, sendob).then(function (ret) { //use ret in function as returned data
				this.store.showLoader = false
				this.isSaving = false
				if(ret.error) this.showKalertError(ret.error)
				if(ret.success) this.$emit('closesave', ret.success.demoId)
			}.bind(this))
		},
		getQDemoKey(question) {
			if(question.trendkey && question.trendkey.length > 0) return question.trendkey
			if(question.colcode && question.colcode.length > 0) return question.colcode
			return question.qid
		},
	},
	watch: {

	},
	mounted() {
		this.selections = []
		this.updateSelectionsFromEdit()
		this.editDemoName = this.sourceDemoOb.name
		//determine if this is an All Users demo (AL only) or just for the active user
		if(this.store.user.superUser === 1 && this.sourceDemoOb.userid === 0) this.saveAsGlobalDemo = true
		else this.saveAsGlobalDemo = false
		window.mouseIsDown = 0
	},
	created() {

	},
}
</script>

<style scoped>
.dhead {
	font-weight: bold;
	line-height: 1.4;
	margin-top: 15px;
	margin-bottom: 5px;
	display: inline-block;
}
.demos-halves-flex {
	display: flex;
	gap: 40px;
}
.demo-left-side {
	width: 48%;
}
.demo-right-side {
	width: 48%;
}
.shown-selections {
	border: 1px solid #CCC;
	padding: 20px;
	line-height: 1.4;
}
.shown-selections .mdi-close {
	cursor: pointer;
	color: #F00;
	margin-left: 5px;
}
.shown-selections .mdi-close:hover {
	color: #A00;
}
.attributes-list {
	overflow-y: auto;
	height: calc(100vh - 410px);
}
.group-head {
	margin-top: 10px;
}
.addor {
	cursor: pointer;
	color: #28A;
	text-decoration: underline;
	margin: 10px 0;
}
.addtogroup {
	cursor: pointer;
	color: #28A;
	font-size: 10px;
}
.arraygroup {
	border: 1px solid #CCC;
	padding: 10px;
	margin: 10px 0;
}
.activearray {
	background: #efefef;
}
</style>
