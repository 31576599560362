<template>
	<div>
		<BlanketOverlayWithPopup title="Respondent QC Summary" width="800" :hastabs="false" v-on:close="closeCancel">
			<template v-slot:main>
				<div class="contained-popup-content">
					<div style="padding: 10px 0 10px 0;">
						<span class="settlbl">Survey ID: {{srvobin.survid}},
							Min Duration (Secs) Flag <input type="number" v-model="minCompleteTimeFlagSecs" />
						</span>
					</div>
					<div style="padding: 0 0 10px 0;">
						Remove Respondent ID <input type="text" v-model="liveRemoveRespId" />
						<button style="margin-left: 5px;" type="button" @click="removeRespondent">Remove</button>
					</div>
					<div style="padding: 10px 0 10px 0;" v-html="resultStr"></div>
				</div>
			</template>
			<template v-slot:buttons>
				<button type="button" class="option-button colbtn" @click="populateContent">Run</button>
				<button type="button" class="option-button" @click="closeCancel">Close</button>
			</template>
		</BlanketOverlayWithPopup>
	</div>
</template>

<script>
import BlanketOverlayWithPopup from "@/components/BlanketOverlayWithPopup";
import {globalMixin} from "@/mixins";
import {store} from '@/store.js'
export default {
	name: "RespondentQCPopup",
	mixins: [globalMixin],
	components: {
		BlanketOverlayWithPopup,
	},
	props: {
		srvobin: Object,
	},
	data: function () {
		return {
			store: store,
			isFetching: false,

			minCompleteTimeFlagSecs: 120,
			liveRemoveRespId: "",

			resultStr: "",

		}
	},
	computed: {

	},
	methods: {
		populateContent() {
			if (this.srvobin) {
				if (this.isFetching === false) {
					this.isFetching = true
					let targ = this.store.urlroot + "central/post-vue/dashboard/respondent-qc";
					let sendob = {
						minTimeSecs: this.minCompleteTimeFlagSecs,
						survid: this.srvobin.survid,
					};
					this.simplePost(targ, sendob).then(function (ret) { //use ret in function as returned data
						let resStr = ''
						if(ret.results) resStr = ret.results
						this.resultStr = resStr
						this.isFetching = false
						this.store.showLoader = false
					}.bind(this));
				}
			}
			this.hasPopulated = true
		},
		removeRespondent() {
			if (this.srvobin) {
				if (this.isFetching === false) {
					this.isFetching = true
					let targ = this.store.urlroot + "central/post-vue/dashboard/remove-respondent";
					let sendob = {
						removeRespId: this.liveRemoveRespId,
						survid: this.srvobin.survid,
					};
					this.simplePost(targ, sendob).then(function (ret) { //use ret in function as returned data
						this.isFetching = false
						if(ret.success && ret.success === true) {
							this.populateContent() //re-run QC
						}
						else {
							this.showKalertError(ret)
						}
					}.bind(this));
				}
			}
		},
		closeCancel() {
			this.$emit('close', false);
		},
	},
	watch: {

	},
	mounted() {

	},
}
</script>
<style scoped>

</style>